import React, { useEffect, useState } from 'react';
import musicIcon from '../assets/musicIcon.svg';
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../redux/cartSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { backend_url } from '../libs/data';
import { fetchBeats } from '../redux/beatsSlice';

// Function to calculate time units
const calculateTimeUnits = (endTime) => {
    const now = new Date().getTime();
    const distance = new Date(endTime).getTime() - now;

    if (distance < 0) {
        return [
            { label: 'Days', value: '00' },
            { label: 'Hours', value: '00' },
            { label: 'Min', value: '00' },
            { label: 'Sec', value: '00' }
        ];
    }

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return [
        { label: 'Days', value: days.toString().padStart(2, '0') },
        { label: 'Hours', value: hours.toString().padStart(2, '0') },
        { label: 'Min', value: minutes.toString().padStart(2, '0') },
        { label: 'Sec', value: seconds.toString().padStart(2, '0') }
    ];
};

const CountdownTimer = ({ endTime,onCountdownEnd }) => {
    const [timeUnits, setTimeUnits] = useState(calculateTimeUnits(endTime));

    useEffect(() => {
        const intervalId = setInterval(() => {
            const updatedTimeUnits = calculateTimeUnits(endTime);
            setTimeUnits(updatedTimeUnits);

            if (updatedTimeUnits.every(unit => unit.value === '00')) {
                clearInterval(intervalId);
                onCountdownEnd(); 
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [endTime, onCountdownEnd]);

    return (
        <div className="flex flex-col items-center py-3">
            <div className="flex items-center gap-2">
                {timeUnits.map((unit, index) => (
                    <React.Fragment key={index}>
                        <div className="text-center py-2 flex flex-col gap-1">
                            <div className="text-sm px-2 py-1 bg-[#FF3E41] rounded-sm">{unit.value}</div>
                            <div className="text-xs text-[#FF3E41]">{unit.label}</div>
                        </div>
                        {index < timeUnits.length - 1 && (
                            <div className="relative bottom-2 text-[#FF3E41]">:</div>
                        )}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

const Cards = ({ beat,userId,user,number,isSelected, onSelect,setMusicIndex,isPlaying,musicIndex,setIsPlaying,selectedBeat,setSelectedBeat }) => {
    const dispatch = useDispatch();
    const cartItems = useSelector((state) => state.cart.cartItems);
    const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);

    const navigate = useNavigate()
   
    const isInCart = cartItems?.some((item) => item._id === beat._id);

    const handleAddToCart = () => {
        if (!isLoggedIn) {
            navigate('/signup');
            return;
        }
        dispatch(addToCart({ userId, beatId: beat._id, isLoggedIn }))
        .then(() => {
            toast.success(`${beat.beatTitle} Added to Cart`);
        })
        .catch((error) => {
            toast.error('Failed to add to Cart');
        });
    };

    const handleRemoveFromCart = () => {
        dispatch(removeFromCart({ userId, beatId: beat._id, isLoggedIn }))
        .then(() => {
            toast.success(`${beat.beatTitle} Removed from Cart`);
        })
        .catch((error) => {
            toast.error('Failed to removed from Cart');
        });
    };

    const handleCountdownEnd = async() => {
        try{
            await axios.post(`${backend_url}/api/changebeattype`,{
                beatId:beat._id
            })
            // dispatch(fetchBeats())
        }
        catch(error)
        {
            console.log(error)
        }
       
       
    };

    const HandleBuy = async()=>{
        if(!isLoggedIn)
        {
            navigate('/login')
            return
        }
        try{
            const res =await axios.post(`${backend_url}/create-checkout-session`,{
                beatDetails:[
                    {
                        price: beat.price,
                        beatName: beat.beatTitle,
                        beatId:beat._id,
                    }

                ],
                email:user.email,
                sessionType:"beatPurchase",
                userId

            })

            window.location.replace(res.data.session.url);
        }
        catch(error)
        {
            const errorMessage = error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
            toast.error(errorMessage);

        }
    }

    return (
        <div className="flex flex-col items-center py-3 mx-2">
            {beat.beatType === 'Exclusive' && (
                <CountdownTimer endTime={beat.countdownEndTime} onCountdownEnd={handleCountdownEnd} />
            )}
            <div className={`${isSelected ? ' bg-[#141414] scale-105 ' : 'bg-[#141414]'} rounded-xl py-5 px-2 duration-300 w-full mt-[-10px]`}  onClick={() => {
                    onSelect();
                    setMusicIndex(number);
                }}>
                <div className="flex py-2 justify-center">
                    <div>
                        <img src={musicIcon} alt="Music Icon" />
                    </div>
                </div>
                <div className="text-center flex flex-col gap-1">
                    <div className="text-xl">{beat.beatTitle}</div>
                    <div className="text-sm text-[#9F9F9F]">{beat.bpm} BPM</div>
                </div>
                <div className="flex justify-center gap-1">
                    {beat.tags.map((tag, index) => (
                        <span key={index} className="bg-black text-white p-1 rounded text-xs">
                            #{tag}
                        </span>
                    ))}
                </div>
                <div className="border-t border-t-[#9F9F9F] mx-7 my-2"></div>
                <div className="text-center mb-2">
                    <div className="text-green-500 text-xl">${beat.price}</div>
                </div>
                <div className="flex flex-col justify-center items-center gap-2 w-full">
                    <button
                         onClick={() => HandleBuy()}
                        className="bg-[#FF3E41] hover:scale-110 hover:transition-transform duration-200 px-4 w-32 py-2 rounded-md"
                    >
                        Buy
                    </button>
                    {
                        isInCart ? 
                        <div className='text-[#FF3E41] text-sm cursor-pointer' onClick={()=>{handleRemoveFromCart()}}>
                        Remove From Cart
                     </div>
                     :
                     <div className='text-[#FF3E41] text-sm cursor-pointer' onClick={()=>{handleAddToCart()}}>
                     Add to Cart
                  </div>

                    }
                  
                </div>
            </div>
        </div>
    );
};

export default Cards;