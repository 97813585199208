import Section from "../components/Section";
import Layout from "../layout/Layout";
import MusicList from "../components/MusicList";
import MusicPlayer from "../components/MusicPlayer";
import PricingModel from "../components/PricingModel";
import PricingModelVip from "../components/PricingModelVip";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchBeats } from "../redux/beatsSlice";
import { fetchUser } from "../redux/userSlice";
import { LoaderComp } from "../UI/Loader";
import { fetchCartItems } from "../redux/cartSlice";

export const VipCatalog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state) => state.favorites.favorites);
  const { user } = useSelector((state) => state.user);
  const { beats, loading } = useSelector((state) => state.beats);
  const [musicIndex, setMusicIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedBeat, setSelectedBeat] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isVipOpen, setIsVipOpen] = useState(false);
  const [tracks, setTracks] = useState([]); // Original beats list for the player
  const [searchQuery, setSearchQuery] = useState("");

  const [isAllow, setIsAllow] = useState(false);
  const [isAllowStems, setIsAllowStems] = useState(false);

  useEffect(() => { 
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchBeats());
  }, [dispatch]);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchCartItems(user._id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user?.hasPasswordChanged === false) {
      navigate("/account-setting");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (beats.length > 0) {
      setTracks(beats);
    }
  }, [beats]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return <LoaderComp />;
  }

  // Filter and sort beats
  const filteredBeats = searchQuery
    ? beats.filter(
        (beat) =>
          beat.beatTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (beat.tags &&
            beat.tags.some((tag) =>
              tag.toLowerCase().includes(searchQuery.toLowerCase())
            ))
      )
    : beats;

  const SortBeats = (beats) => {
    return beats.filter((beat) => beat.beatType !== "Exclusive");
  };

  const exLength = filteredBeats
    ? filteredBeats.filter((beat) => beat?.beatType === "Exclusive").length
    : 0;

  const HandleClosePricing = () => {
    setIsOpen(false);
    setSelectedBeat(null);
  };

  const HandleCloseVipPricing = () => {
    setIsVipOpen(false);
  };

  const handleSignup = () => {
    const currentPath = window.location.pathname;
    navigate(`/signup?returnUrl=${encodeURIComponent(currentPath)}`);
    return;
  }

  return (
    <Layout>
      <Section>
        <div className="py-4">
          <h1 className="text-yellow-500 text-[35px] md:text-[52px] py-4 text-2xl font-bold text-center relative">
            VIP CATALOG
          </h1>
          <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center ">
              <b>HOW TO GET ACCESS  : </b>
            </p>
          <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center ">
              <b>STEP 1</b><br />Listen to the beats included below 
            </p>
            <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center">
            <b> STEP 2</b><br />Click on yellow banner button
            </p>
            <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center">
            <b>STEP 3</b><br />Create your account <br />then click again on yellow banner button
            </p>
            <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center">
              <b>
              STEP 4</b><br />Fill payment info (Card/Paypal/Apple pay)
              </p>
              <p className="text-[#FFFFFF] text-[15px] md:text-xl text-center">
              <b>
              STEP 5</b><br />Now you can download and use all 300+ beats <br />and all new ones by clicking the download button 
              </p>
        </div>
        <div className="w-full mt-[-30px]">
          <MusicList
            exLength={exLength}
            selectedBeat={selectedBeat}
            setSelectedBeat={setSelectedBeat}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            beats={filteredBeats.filter(
              (beat) => beat.beatType !== "Exclusive"
            )}
            scrollToTop={scrollToTop}
            musicIndex={musicIndex}
            setMusicIndex={setMusicIndex}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            isAllow={isAllow}
            setIsAllow={setIsAllow}
            isAllowStems={isAllowStems}
            setIsAllowStems={setIsAllowStems}
          />
        </div>
        {user && user.userType !== "vipUser" && (
          <div className="fixed bottom-36 left-0 w-full z-20 bg-yellow-500 text-black text-lg py-4 px-6 shadow-lg ">
            <div className="md:hidden  flex flex-col items-center justify-center">
              <button
                className="text-center w-full"
                onClick={() => setIsVipOpen(true)}
              >
                <p>
                  <b>
                    {" "}
                    GET FULL ACCESS 
                  </b>
                  <br />
                  (click here)
                </p>
              </button>
            </div>

            <div className="hidden md:flex items-center justify-center">
              <p className="mr-3">
              Get FULL access to the<b> 300+ beats</b> VIP catalog + <b>ALL </b>new beats
              for<b> $99</b>
              </p>
              <button
                className="bg-black text-white py-2 px-4 rounded"
                onClick={() => setIsVipOpen(true)}
              >
                GET ACCESS
              </button>
            </div>
          </div>
        )}

        {user === undefined && (
          <div className="fixed bottom-40 lg:bottom-32 left-0 w-full z-20 bg-yellow-500 text-black text-lg py-4 px-6 shadow-lg">
            <div
              onClick={handleSignup}
              className="flex flex-col items-center justify-center md:hidden"
            >
              <p className="text-center">
                <b>GET FULL ACCESS</b>
                <br />
                (click here)
              </p>
            </div>

            <div className="hidden md:flex items-center justify-center ">
              <p className="mr-3">
              Get FULL access to the<b> 300+ beats</b> VIP catalog + <b>ALL</b> new beats
              for <b>$99</b>
              </p>

              <button className="bg-black text-white py-2 px-4 rounded" onClick={handleSignup}>
                GET ACCESS
              </button>
            </div>
          </div>
        )}

        <MusicPlayer
          selectedBeat={selectedBeat}
          setSelectedBeat={setSelectedBeat}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tracks={SortBeats(filteredBeats)}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          favorites={favorites}
          userId={user?._id}
          user={user}
          scrollToTop={scrollToTop}
          musicIndex={musicIndex}
          setMusicIndex={setMusicIndex}
          isAllow={isAllow}
          setIsAllow={setIsAllow}
          isAllowStems={isAllowStems}
          setIsAllowStems={setIsAllowStems}
        />
      </Section>
      {isOpen && (
        <PricingModel
          isOpen={isOpen}
          isAllow={isAllow}
          loading={loading}
          isAllowStems={isAllowStems}
          setIsAllow={setIsAllow}
          setIsAllowStems={setIsAllowStems}
          onClose={HandleClosePricing}
          user={user}
          beat={selectedBeat}
          scrollToTop={scrollToTop}
        />
      )}

      {isVipOpen && (
        <PricingModelVip user={user} onClose={HandleCloseVipPricing} />
      )}
    </Layout>
  );
};
