import Section from "../components/Section";
import Layout from "../layout/Layout";
import MusicPlayer from "../components/MusicPlayer";
import PricingModel from "../components/PricingModel";
import PricingModelVip from "../components/PricingModelVip";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { fetchBeats } from "../redux/beatsSlice";
import { fetchUser } from "../redux/userSlice";
import { LoaderComp } from "../UI/Loader";
import { fetchCartItems } from "../redux/cartSlice";
import Cards from "../components/Cards";

export const ExclusivesCatalog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state) => state.favorites.favorites);
  const { user } = useSelector((state) => state.user);
  const { beats, loading } = useSelector((state) => state.beats);
  const [musicIndex, setMusicIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedBeat, setSelectedBeat] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isVipOpen, setIsVipOpen] = useState(false);
  const [tracks, setTracks] = useState([]); // Original beats list for the player
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [isAllow, setIsAllow] = useState(false);
  const [isAllowStems, setIsAllowStems] = useState(false);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchBeats());
  }, [dispatch]);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchCartItems(user._id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user?.hasPasswordChanged === false) {
      navigate("/account-setting");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (beats.length > 0) {
      setTracks(beats);
    }
  }, [beats]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return <LoaderComp />;
  }

  // Filter and sort beats
  const filteredBeats = searchQuery
    ? beats.filter(
        (beat) =>
          beat.beatTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (beat.tags &&
            beat.tags.some((tag) =>
              tag.toLowerCase().includes(searchQuery.toLowerCase())
            ))
      )
    : beats;

  const SortBeats = (beats) => {
    return beats.filter((beat) => beat.beatType === "Exclusive");
  };

  const exLength = filteredBeats
    ? filteredBeats.filter((beat) => beat?.beatType === "Exclusive").length
    : 0;

  const handleSelect = (index) => {
    const beat = filteredBeats[index];
    setSelectedIndex(index);
    setMusicIndex(index);
    setSelectedBeat(beat);
    setIsPlaying(true);
  };

  const HandleClosePricing = () => {
    setIsOpen(false);
    setSelectedBeat(null);
  };

  const HandleCloseVipPricing = () => {
    setIsVipOpen(false);
  };

  const handleSignup = () => {
    const currentPath = window.location.pathname;
    navigate(`/signup?returnUrl=${encodeURIComponent(currentPath)}`);
    return;
  };

  return (
    <Layout>
      <Section>
        <div className="py-0 px-0">
          <h1 className="text-white text-[35px] md:text-[52px] py-6 text-2xl font-bold text-center">
            NEW EXCLUSIVES
          </h1>
          <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center ">
            <b>Get exclusive ownership of fresh, unused beats. </b>
          </p>
          <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center">
            Secure the rights to your next hit before someone else does,
          </p>
          <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center">
            or before quarantine period ends and the beat goes public.
          </p>
          <p className="text-[#FF3E41] text-[13px] md:text-xl text-center">
            <b>
              Includes Stems, FREE Mixing/Mastering (time-limited), and 100%
              Royalties
            </b>
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5 px-5">
          {filteredBeats
            .filter((beat) => beat.beatType === "Exclusive")
            .map((item, index) => (
              <Cards
                key={index}
                beat={item}
                userId={user?._id}
                user={user}
                selectedBeat={selectedBeat}
                setSelectedBeat={setSelectedBeat}
                musicIndex={musicIndex}
                setMusicIndex={setMusicIndex}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                isSelected={musicIndex === index}
                onSelect={() => handleSelect(index)}
                number={index}
              />
            ))}
        </div>

        <MusicPlayer
          selectedBeat={selectedBeat}
          setSelectedBeat={setSelectedBeat}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tracks={SortBeats(filteredBeats)}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          favorites={favorites}
          userId={user?._id}
          user={user}
          scrollToTop={scrollToTop}
          musicIndex={musicIndex}
          setMusicIndex={setMusicIndex}
          isAllow={isAllow}
          setIsAllow={setIsAllow}
          isAllowStems={isAllowStems}
          setIsAllowStems={setIsAllowStems}
        />
      </Section>
      {isOpen && (
        <PricingModel
          isOpen={isOpen}
          isAllow={isAllow}
          loading={loading}
          isAllowStems={isAllowStems}
          setIsAllow={setIsAllow}
          setIsAllowStems={setIsAllowStems}
          onClose={HandleClosePricing}
          user={user}
          beat={selectedBeat}
          scrollToTop={scrollToTop}
        />
      )}

      {isVipOpen && (
        <PricingModelVip user={user} onClose={HandleCloseVipPricing} />
      )}
    </Layout>
  );
};
